import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/enterprise_program_management.jpg'
import portfolio_management from '../../images/pages/portfolio_management.png'
import project_leader from '../../images/pages/project_leader.png'
import person from '../../images/person.png'
import ContactText from '../../components/ContactText'

const pageTitle = "Enterprise Program Management";
const pageSLug = "enterprise-program-management";
class EnterpriseProgram extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We focus on controlling strategic initiatives and delivering programs and projects.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Business transformation and change continues to accelerate within organizations.
                    </h3>
                    <p>To address this, Enterprise Program Management (EPM) has rapidly evolved from a supporting 
                        function in companies to a core function which is crucial in prioritizing, managing, aligning,
                         and overseeing efforts.</p>
                    <p>Close collaboration with our clients is key to understanding their assessment analysis services, 
                        programs, goals, and drivers, offering portfolio, project, coaching, and assessment analysis services.</p>
                    <span className="break"></span>
                    <h4>Enterprise Program Management Vision</h4>
                    <p>Focuses on selecting the right projects, executing them the right way, and creating real 
                        business results.</p>
                    <p>Not sure where to start, our team is always ready to help.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                   <span className="break"></span>
                   <h4>Portfolio Management</h4>
                   <p>Our experts can establish effective project portfolio management processes and supporting tools. 
                       That will ensure the focus stays on the right project and aligns with your company’s strategic goals.</p>
                    <p>At Nuerex, we help you find the right way to execute your projects better, ensure strategic alignment, 
                        and measure project value.</p>
                    <h6>Organizational benefits include:</h6>
                    <ul>
                        <li>Ensuring alignment with the strategy</li>
                        <li>Providing a quick method for evaluating project value</li>
                        <li>Providing numeric rankings</li>
                        <li>Faster decision making</li>
                        <li>An increase in confidence</li>
                        <li>Adaptability</li>
                    </ul>
                <img src={portfolio_management} />
                <span className="break"></span>
                <h4>PMO capability Development</h4>
                <p>Enterprise Program Management (EPM) will provide value in line with the overall strategy.</p>
                <p>There is no one size fits all in creating and optimizing a PMO. We work together to understand your 
                    unique needs and outline the benefits of an EPM and PMO within your organization. We establish a 
                    vision and an operating model for the EPM and PMO based on what is most appropriate for your 
                    organization.</p>
                <p>Our supporting toolkits and comprehensive methodology rapidly improve your EPM with a focus on 
                    building internal capabilities to improve functionality.</p>
                <span className="break"></span>
                <h4>Project Leadership</h4>
                <p>Setting a clear vision, creating the correct culture, and supporting leadership talent is vital in project leadership. 
                    Ideally, a project manager is an adaptive leader with the following hard and soft skills.</p>
                <img src={project_leader} />
                <p>We help provide this skillset by applying deep knowledge in project management methods, an in-depth understanding 
                    of business strategies and goals, and strong decision-making skills.</p>
                <span className="break"></span>
                <h4>Project Delivery and Recovery</h4>
                <p>You need the right people to get projects back in order. Significant investments are made to develop 
                    high quality, reliable resources that integrate flawlessly into your organizational culture. We offer 
                    experts who can assess core issues, restructure project efforts, and see the project to completion.</p>
                <h6>Our project and program managers can:</h6>
                <ul>
                    <li>Provide an independent view to advance challenging projects</li>
                    <li>Create collaborative relationships within a team and apply consistent project management practices</li>
                    <li>Use risk identification and mitigation solutions</li>
                    <li>Provide specialized expertise to guide team members to the correct answer</li>
                    <li>Lead with an executive presence</li>
                </ul>
                <h3 className="intext">Business is never isolated!</h3>
                <p>Constant digital and technological innovations are empowering business transformation; growing and evolving 
                    requires adapting to new services or designing your own. With our expertise and experience, we can help 
                    you do both.</p>
                <Link to="/">Discover our digital services</Link>
                <Link to="/">Discover our technological services</Link>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Our Services</h2>
                  <h6 className="subtitle">We can elevate your project and program management:</h6>
              </div>
              <div className="columns is-multiline">
                  
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Portfolio Management</h2>
                        <p className="desc">We strategically align your project portfolios, streamline annual planning cycles, 
                        and provide relevant metrics. As well as balancing resources across your organization and monitoring 
                        the progress of project and program investments.</p>
                      </div>
                  </div>
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">PMO Capability Development</h2>
                        <p className="desc">We help you establish and optimize PMO capabilities to improve project and program 
                        delivery. By providing a comprehensive assessment of your current PMO capabilities, including organizational 
                        alignment, structure, methodologies, and tools.</p>
                      </div>
                  </div>
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Mergers and Divestitures</h2>
                        <p className="desc">We can work together to evaluate, manage, and define the complicated 
                        relationship associated with business and technology integration.</p>
                      </div>
                  </div>

                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Project Leadership</h2>
                        <p className="desc">Project Leadership and management can be increased across your 
                        organization by providing competency and skill assessments, project management training, 
                        as well as coaching and mentoring.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default EnterpriseProgram
